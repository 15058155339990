'use strict';

angular.module('shared.services.settingService', ['shared.services.cachedLookupService'])
    .service('settingService', [
        'httpCoreApi',
        'cachedLookupService',
        function (httpCoreApi, cachedLookupService) {
            const service = this;

            const urlConfigSettingsBase = "Person/ConfigSettings";

            const httpCoreApiSettings = {
                controllerType: httpCoreApi.controllerTypes.User
            };

            service.getPersonSetting = function (settingName) {
                return httpCoreApi.get(`${urlConfigSettingsBase}/${settingName}`, httpCoreApiSettings)
                    .then(function (response) {
                        return response.data.value;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            service.savePersonSetting = function (settingName, settingValue) {
                return httpCoreApi.put(urlConfigSettingsBase, {
                    values: [
                      {
                        settingName: settingName,
                        value: settingValue
                      }
                    ]
                }, httpCoreApiSettings)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (e) {
                        return { isError: true, errorData: e };
                    });
            };

            const eventTypesSetting = 'Reception_Register_Events_Search_Filter';

            service.buildEventTypeSetting = function(data) {
                    return cachedLookupService.isParent().then(function(isParent) {
                    var savedEventTypesConfig = data ? JSON.parse(data) : {};
                    var eventTypesConfig = {};
                    eventTypesConfig.includeParentsEvening = isParent ? true : (savedEventTypesConfig.includeParentsEvening != undefined ? savedEventTypesConfig.includeParentsEvening : true);
                    eventTypesConfig.includeFeeOnlyEvents = savedEventTypesConfig.includeFeeOnlyEvents != undefined ? savedEventTypesConfig.includeFeeOnlyEvents : false;
                    eventTypesConfig.includeTransportEvents = savedEventTypesConfig.includeTransportEvents != undefined ? savedEventTypesConfig.includeTransportEvents : true;
                    if (!data) {
                        eventTypesConfig.unavailable = true;
                    }
                    return eventTypesConfig;
                });
            };

            service.getEventTypesSetting = function () {
                return service.getPersonSetting(eventTypesSetting)
                .then(function (data)
                {
                    return service.buildEventTypeSetting(data);
                })
                .catch(function (e) {
                    console.log(e);
                    return service.buildEventTypeSetting();
                });
            };

            service.saveEventTypesSetting = function (settingValue) {

                return service.savePersonSetting(eventTypesSetting, settingValue);

            };

            return this;
        }]
    );