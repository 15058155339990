import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService, OAuthEvent, ParsedIdToken } from 'angular-oauth2-oidc';
import { ValidationParams } from 'angular-oauth2-oidc/token-validation/validation-handler';
import { filter, Subscription } from 'rxjs';

@Injectable()
export class CustomOAuthService extends OAuthService {
  tokenReceivedSubscription!: Subscription;
  accessTokenExpiresAt: number;
  idTokenExpiresAt: number;

  override configure(config: AuthConfig) {
    super.configure(config);

    this.tokenReceivedSubscription = this.events
      .pipe(filter((e: OAuthEvent) => e.type === 'token_received' || e.type === 'token_refreshed'))
      .subscribe(() => {
        this.accessTokenExpiresAt = CustomOAuthService.calculateTokenExpiry(this.getAccessToken());
        this.idTokenExpiresAt = CustomOAuthService.calculateTokenExpiry(this.getIdToken());
      });
  }

  private static calculateDecodedTokenExpiry(decodedToken: any) {
    const expiresInMillis = (parseInt(decodedToken.exp) - parseInt(decodedToken.iat)) * 1000;

    // Create a local time expiry for the token (minus 10 seconds)
    return Date.now() + expiresInMillis - 10000;
  }

  private static calculateTokenExpiry(token: string): number {
    const decodedToken = CustomOAuthService.decodeJwt(token);

    if (decodedToken) {
      return CustomOAuthService.calculateDecodedTokenExpiry(decodedToken);
    }

    return null;
  }

  private static decodeBase64(base64: string): string {
    return atob(base64.replace(/-/g, '+').replace(/_/g, '/'));
  }

  private static decodeJwt(token: string): any {
    if (!token) {
      return null;
    }
  
    try {
      const payloadBase64 = token.split('.')[1]; // Extract payload part
      const decodedJson = CustomOAuthService.decodeBase64(payloadBase64);

      return JSON.parse(decodedJson); // Convert to JSON object
    } catch (error) {
      console.error('Invalid JWT', error);
      return null;
    }
  }

  protected override storeAccessTokenResponse(accessToken: string, refreshToken: string, expiresIn: number, grantedScopes: String, customParameters?: Map<string, string>): void {
    super.storeAccessTokenResponse(accessToken, refreshToken, expiresIn, grantedScopes, customParameters);

    const offsetExpiry = CustomOAuthService.calculateTokenExpiry(accessToken);
    this._storage.setItem('expires_at', '' + offsetExpiry);
   }

  override getAccessTokenExpiration(): number {
    if (this.accessTokenExpiresAt) {
      return this.accessTokenExpiresAt;
    }

    return super.getAccessTokenExpiration();
  }

  override getIdTokenExpiration(): number {
    if (this.idTokenExpiresAt) {
      return this.idTokenExpiresAt;
    }

    return super.getIdTokenExpiration();
  }
  
  override hasValidAccessToken(): boolean {
    if (this.getAccessToken()) {
      return Date.now() < this.getAccessTokenExpiration();
    }

    return super.hasValidAccessToken();
  }

  override hasValidIdToken(): boolean {
    if (this.getIdToken()) {
      return Date.now() < this.getIdTokenExpiration();
    }

    return super.hasValidIdToken();
  }

  private getClockSkewInMsec2(defaultSkewMsc = 600_000) {
    if (!this.clockSkewInSec && this.clockSkewInSec !== 0) {
      return defaultSkewMsc;
    }
    return this.clockSkewInSec * 1000;
  }

  /**
   * Based on processIdToken from the super class. See https://github.com/manfredsteyer/angular-oauth2-oidc/blob/master/projects/lib/src/oauth-service.ts
   */
  public processIdToken(
    idToken: string,
    accessToken: string,
    skipNonceCheck = false
  ): Promise<ParsedIdToken> {
    const tokenParts = idToken.split('.');
    const headerBase64 = this.padBase64(tokenParts[0]);
    const headerJson = CustomOAuthService.decodeBase64(headerBase64);
    const header = JSON.parse(headerJson);
    const claimsBase64 = this.padBase64(tokenParts[1]);
    const claimsJson = CustomOAuthService.decodeBase64(claimsBase64);
    const claims = JSON.parse(claimsJson);

    let savedNonce;
    if (
      this.saveNoncesInLocalStorage &&
      typeof window['localStorage'] !== 'undefined'
    ) {
      savedNonce = localStorage.getItem('nonce');
    } else {
      savedNonce = this._storage.getItem('nonce');
    }

    if (Array.isArray(claims.aud)) {
      if (claims.aud.every((v) => v !== this.clientId)) {
        const err = 'Wrong audience: ' + claims.aud.join(',');
        this.logger.warn(err);
        return Promise.reject(err);
      }
    } else {
      if (claims.aud !== this.clientId) {
        const err = 'Wrong audience: ' + claims.aud;
        this.logger.warn(err);
        return Promise.reject(err);
      }
    }

    if (!claims.sub) {
      const err = 'No sub claim in id_token';
      this.logger.warn(err);
      return Promise.reject(err);
    }

    /* For now, we only check whether the sub against
      * silentRefreshSubject when sessionChecksEnabled is on
      * We will reconsider in a later version to do this
      * in every other case too.
      */
    if (
      this.sessionChecksEnabled &&
      this.silentRefreshSubject &&
      this.silentRefreshSubject !== claims['sub']
    ) {
      const err =
        'After refreshing, we got an id_token for another user (sub). ' +
        `Expected sub: ${this.silentRefreshSubject}, received sub: ${claims['sub']}`;

      this.logger.warn(err);
      return Promise.reject(err);
    }

    if (!claims.iat) {
      const err = 'No iat claim in id_token';
      this.logger.warn(err);
      return Promise.reject(err);
    }

    if (!this.skipIssuerCheck && claims.iss !== this.issuer) {
      const err = 'Wrong issuer: ' + claims.iss;
      this.logger.warn(err);
      return Promise.reject(err);
    }

    if (!skipNonceCheck && claims.nonce !== savedNonce) {
      const err = 'Wrong nonce: ' + claims.nonce;
      this.logger.warn(err);
      return Promise.reject(err);
    }
    // at_hash is not applicable to authorization code flow
    // addressing https://github.com/manfredsteyer/angular-oauth2-oidc/issues/661
    // i.e. Based on spec the at_hash check is only true for implicit code flow on Ping Federate
    // https://www.pingidentity.com/developer/en/resources/openid-connect-developers-guide.html
    if (
      Object.prototype.hasOwnProperty.call(this, 'responseType') &&
      (this.responseType === 'code' || this.responseType === 'id_token')
    ) {
      this.disableAtHashCheck = true;
    }
    if (
      !this.disableAtHashCheck &&
      this.requestAccessToken &&
      !claims['at_hash']
    ) {
      const err = 'An at_hash is needed!';
      this.logger.warn(err);
      return Promise.reject(err);
    }

    const now = this.dateTimeService.now();
    const issuedAtMSec = now;
    const expiresAtMSec = CustomOAuthService.calculateDecodedTokenExpiry(claims);
    const clockSkewInMSec = this.getClockSkewInMsec2();

    if (
      issuedAtMSec - clockSkewInMSec >= now ||
      expiresAtMSec + clockSkewInMSec - this.decreaseExpirationBySec <= now
    ) {
      const err = 'Token has expired';
      console.error(err);
      console.error({
        now: now,
        issuedAtMSec: issuedAtMSec,
        expiresAtMSec: expiresAtMSec,
      });
      return Promise.reject(err);
    }

    const validationParams: ValidationParams = {
      accessToken: accessToken,
      idToken: idToken,
      jwks: this.jwks,
      idTokenClaims: claims,
      idTokenHeader: header,
      loadKeys: () => this.loadJwks(),
    };

    if (this.disableAtHashCheck) {
      return this.checkSignature(validationParams).then(() => {
        const result: ParsedIdToken = {
          idToken: idToken,
          idTokenClaims: claims,
          idTokenClaimsJson: claimsJson,
          idTokenHeader: header,
          idTokenHeaderJson: headerJson,
          idTokenExpiresAt: expiresAtMSec,
        };
        return result;
      });
    }

    return this.checkAtHash(validationParams).then((atHashValid) => {
      if (!this.disableAtHashCheck && this.requestAccessToken && !atHashValid) {
        const err = 'Wrong at_hash';
        this.logger.warn(err);
        return Promise.reject(err);
      }

      return this.checkSignature(validationParams).then(() => {
        const atHashCheckEnabled = !this.disableAtHashCheck;
        const result: ParsedIdToken = {
          idToken: idToken,
          idTokenClaims: claims,
          idTokenClaimsJson: claimsJson,
          idTokenHeader: header,
          idTokenHeaderJson: headerJson,
          idTokenExpiresAt: expiresAtMSec,
        };
        if (atHashCheckEnabled) {
          return this.checkAtHash(validationParams).then((atHashValid) => {
            if (this.requestAccessToken && !atHashValid) {
              const err = 'Wrong at_hash';
              this.logger.warn(err);
              return Promise.reject(err);
            } else {
              return result;
            }
          });
        } else {
          return result;
        }
      });
    });
  }
}
