import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DoBootstrap, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { setAngularJSGlobal, UpgradeModule } from '@angular/upgrade/static';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore
} from '@ngx-translate/core';
import { AuthInterceptor } from '@sb-shared/interceptors/auth-interceptor.service';
import { DateTimeInterceptor } from '@sb-shared/interceptors/date-time-inteceptor.service';
import { localeProviders } from '@sb-shared/modules/locales';
import { CustomDateParserFormatter } from '@sb-shared/other/custom-date-parser-formatter';
import { SbMissingTranslationHandler } from '@sb-shared/other/missing-translation-handler';
import { ErrorHandlerService } from '@sb-shared/services/error-handing.service';
import { NotTranslatedService } from '@sb-shared/services/not-translated.service';
import { OrganisationTranslateLoaderService } from '@sb-shared/services/organisation-translate-loader.service';
import { OrganisationService } from '@sb-shared/services/organisation.service';
// import angularJS components
import * as angular from 'angular';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxEchartsModule } from 'ngx-echarts';
import { environment } from 'src/environments/environment';
import './angularJs';
import { ng1AppModule } from './angularJs/ng1-app.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { EveryBuddy, PowerbiClient } from './core/window-properties';
import { LoggingService } from './shared/services/logging.service';
import { SharedModule } from './shared/shared.module';
import { EveryBuddyConstants } from '@sb-core/models/everybuddy-constants';
import { CustomOAuthService } from './shared/services/custom-oauth-service.service';

declare global {
  interface Window {
    EveryBuddy: EveryBuddyConstants;
    PowerbiClient: any;
    ReactNativeWebView: any;
    LOU: any;
  }
}

setAngularJSGlobal(angular);
@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    AuthModule,
    CoreModule,
    UpgradeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: OrganisationTranslateLoaderService,
        deps: [HttpClient, OrganisationService]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: SbMissingTranslationHandler,
        deps: [NotTranslatedService]
      },
      isolate: false
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl, environment.oldApiUrl],
        sendAccessToken: true
      }
    }),
    NgMultiSelectDropDownModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    AppRoutingModule
  ],
  providers: [
    { provide: OAuthService, useClass: CustomOAuthService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateTimeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: OAuthStorage,
      useFactory: () => localStorage
    },
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatter
    },
    TranslateStore,
    TranslateService,
    LoggingService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    ...localeProviders
  ]
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule) {}
  public ngDoBootstrap(app: any): void {
    window.EveryBuddy = EveryBuddy;
    window['powerbi-client'] = PowerbiClient;
    this.upgrade.bootstrap(document.body, [ng1AppModule.name], { strictDi: true });
  }
}
