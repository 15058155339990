const NewGroupFields = {
  NewGroupLocation: 'newGroupLocation',
  NewGroupActivity: 'newGroupActivityId',
  NewGroupGender: 'newGroupGenderType',
  NewGroupDescription: 'newGroupDescription',
  NewGroupCode: 'newGroupCode',
  NewGroupBillingCode: 'newGroupBillingCode',
  NewGroupLeadStaff: 'newGroupLeadStaffId',
  NewGroupStaffList: 'staffList',
  NewGroupStaff: 'newGroupStaffIds',
  NewGroupName: 'newGroupName'
} as const;

export const EventFieldIds = {
  ConsentDeadlineSwitch: 'consentDeadlineSwitch',
  CustomForm: 'customFormId',
  CustomFormSwitch: 'customFormSwitch',
  Date: 'date',
  EventType: 'calendarEventCategoryTypeId',
  Location: 'locationId',
  MeetingLocation: 'meetingLocationId',
  MeetingTime: 'meetingTime',
  NoOfPlacesSwitch: 'noOfPlacesSwitch',
  NotifyType: 'notifyTypeId',
  PickupLocation: 'pickupLocationId',
  PickupTime: 'pickupTime',
  PortalLink: 'portalLinkId',
  RepeatEnd: 'repeatEnd',
  RepeatEventCount: 'repeatEventCount',
  RepeatType: 'repeatTypeId',
  SelectionType: 'availabilityRequirement',
  Team: 'teamId',
  Title: 'title',
  ...NewGroupFields
} as const;
