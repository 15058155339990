import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { CommonChars } from '@sb-shared/globals/common-chars';
import { Options } from '@sb-shared/models/UI/filter';
import { DateGroup } from '@sb-shared/models/date-group';
import { isAfter, isBefore, startOfDay } from 'date-fns';
import { isArray } from 'lodash-es';
import { DateGroupItem } from './../models/date-group';
import { DateFormats } from '@sb-shared/globals/date-formats';

@Injectable({
  providedIn: 'root'
})
export class ArrayService {

  // Implementation from https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
  // Better method exists for ES6.
  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return Object.hasOwnProperty.call(seen, k) ? false : (seen[k] = true);
    });
  }

  updateArrayFromArray(sourceArray: unknown[], destArray: unknown[], keyField: string, fieldsToUpdate: string[], childArraySettings: unknown[]) {

    for (let i = sourceArray.length; i--;) {
      // find the existing entry in our current dataset
      const newRecord = sourceArray[i];
      let existingRecord = null;
      for (let j = destArray.length; j--;) {
        if (destArray[j][keyField] === newRecord[keyField]) {
          existingRecord = destArray[j];
          break;
        }
      }

      if (existingRecord !== null) {
        // update!
        for (let k = fieldsToUpdate.length; k--;) {

          if (Array.isArray(existingRecord[fieldsToUpdate[k]])) {

            // special case for arrays- look up the settings
            if (childArraySettings && childArraySettings[fieldsToUpdate[k]]) {
              const settings = childArraySettings[fieldsToUpdate[k]];
              // we have a handler- else, just blaster over it
              this.updateArrayFromArray(newRecord[fieldsToUpdate[k]], existingRecord[fieldsToUpdate[k]], settings.keyField, settings.fieldsToUpdate, settings.childArraySettings);
            }
            else {
              existingRecord[fieldsToUpdate[k]] = newRecord[fieldsToUpdate[k]];
            }
          }
          else {
            existingRecord[fieldsToUpdate[k]] = newRecord[fieldsToUpdate[k]];
          }
        }
      }
    }
  }

  groupByDate(collection: any[], property?: string, reverseInDay?: boolean): DateGroup {
    if (!collection) return [];
    property = property || 'from' || 'date';
    // Sort by date and time
    collection.sort((a, b) => this.sortDatePropFn(a, b, property));
    if (reverseInDay) {
      collection.reverse();
    }
    // Group by date
    return collection.reduce((groups, item) => {
      const date = formatDate(startOfDay(new Date(item[property] || item)), DateFormats.Data, 'en-US');
      const itemGroup = groups.find((group: DateGroupItem) => group.date === date);
      if (itemGroup) {
        itemGroup.items.push(item);
      } else {
        groups.push({
          date: date,
          items: [item]
        });
      }
      return groups;
    }, []).sort((a, b) => this.sortDatePropFn(a, b, 'date'));
  }

  generateOptions(array: any[], addAllOption: boolean, allLabel: string, idProp?: string, nameProp?: string) {
    if (!Array.isArray(array)) {
      return array;
    }

    // Map option properties from list

    if (idProp && nameProp) {
      const localeSort = (a, b) => a.name.localeCompare(b.name);
      const newItems = array.map((item) => {
        return {
          ...(item as object),
          id: item[idProp],
          name: item[nameProp]
        };
      });
      const uniqueNewItems = this.uniqueBy(newItems, (item) => item.id);
      uniqueNewItems.sort(localeSort);
      array = uniqueNewItems;
    }

    const allOptionExists = array.find((option: { id?}) => !option.id || option.id === -1);
    const selectOptions = (addAllOption && !allOptionExists) ? [
      {
        id: -1,
        name: allLabel || 'SB_All'
      }
    ] : [];
    if (!array[0] || typeof array[0] !== 'string') {
      return addAllOption ? [...selectOptions, ...array] : array;
    }

    return selectOptions.concat(array.map((item, index) => {
      return {
        id: index + 1,
        name: item
      };
    }));
  }

  addCountsToOptions(optionArray: Options, results: unknown[], idProp: string): Options {
    return optionArray.map(option => {
      return {
        ...option,
        count: results.filter(result => result[idProp] == option.id).length
      }
    })
  }

  addIdsToArray(array: unknown[]) {
    return array.map(
      (item, index) => {
        return {
          ...(item as object),
          id: index
        }
      }
    )
  }

  sortDatePropFn(a: unknown, b: unknown, property: string) {
    const aValue = new Date(a[property]);
    const bValue = new Date(b[property]);

    return isAfter(aValue, bValue) ? 1 : isBefore(aValue, bValue) ? -1 : 0;
  }

  // To given array - removes item if present, adds if not.
  toggleArrayItem(array: unknown[], item: unknown) {
    if (array.some(arrayItem => arrayItem == item)) {
      array.splice(array.indexOf(item), 1);
    }
    else {
      array.push(item);
    }
  }

  // Determines if object has truthy values for ALL of the properties in the array. Properties with array values must be non-empty.
  // In addition, a given array entry can be a comma-separated list i.e. in which case any ONE of those properties has to evaluate as truthy (or non-empty array).
  keyMatch(array: string[], obj: object) {
    // Check all key items match
    return !array || array.every(key => {
      // Check one of comma-separated properties match
      return key.split(CommonChars.Comma).some(property => {
        const value = obj[property]
        return isArray(value) && value.length > 0 || value;
      });
    });
  }

}
